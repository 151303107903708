<template>
  <div class="row m-0 bg_left h-100å" :style="{
    height: height || windowHeight + 'px',
    background: 'linear-gradient(0deg, rgba(38, 38, 51, 0.5), rgba(38, 38, 51, 0.5)), url('+backgroundImg() +') no-repeat ' +  -backgroundX + 'px 0px',
    backgroundSize: 'cover !important',
  }">
    <div class="col-12 p-0 overflow_y h-100 px-5 pt-5 pb-4 white">
      <div class="h-100">
        <div class="row p-0 pt-4 m-0">
          <div class="col small-title">
            {{mySmallTitle}}
          </div>
        </div>
        <div class="row p-0 pt-2 m-0">
          <div class="col font-playfair big-title">
            {{myTitle}}
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-0 bottom-logo text-center">
            <img src="../assets/images/more_logo.png" class="w-50">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Sidebar',
  props: {
    title: {
      type: String,
      required: false,
      default: () => null
    },
    smallTitle: {
      type: String,
      required: false,
      default: () => null
    },
    height: {
      type: String,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      // mySmallTitle: 'My Personal Travel Planner',
      mySmallTitle: '',
      myTitle: 'Welcome to MORE Family Concierge'
    }
  },
  computed: {
    ...mapGetters([
      'serverMedia',
      'windowHeight',
      'windowWidth'
    ]),
    backgroundX () {
      return this.windowWidth / 100 * 14
    }
  },
  created () {
    this.mySmallTitle = this.smallTitle || this.mySmallTitle
    this.myTitle = this.title || this.myTitle
  },
  methods: {
    backgroundImg () {
      return this.serverMedia + 'images/ogp/' + 'bg_1.jpg'
    }
  }
}
</script>
<style scoped>
  .bg-left {
    min-height:100%;
    height: 100%;
    max-height: 100%;
  }
  .white {
    color: #FFF !important;
  }
  .small-title {
    font-size: .69vw;
    text-transform: uppercase;
  }
  .big-title {
    font-size: 3vw;
    line-height: 3.5vw;
    letter-spacing: 0px;
    font-weight: 600;
  }
  .bottom-logo {
    position: fixed;
    bottom: 4vh;
    width: 25vw;
    left: 0;
  }
</style>

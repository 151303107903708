import { render, staticRenderFns } from "./SideBar202012.vue?vue&type=template&id=d0d10414&scoped=true&"
import script from "./SideBar202012.vue?vue&type=script&lang=js&"
export * from "./SideBar202012.vue?vue&type=script&lang=js&"
import style0 from "./SideBar202012.vue?vue&type=style&index=0&id=d0d10414&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0d10414",
  null
  
)

export default component.exports